<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="flex flex-wrap justify-start">
          <vs-button @click="addVoucherRule" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="voucherRulesList"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          rowSelection="single"
          :pagination="true"
          :context="context"
          :suppressPaginationPanel="false"
        >
        </ag-grid-vue>

      </vx-card>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererVoucherRulesActiveCheckbox from './cell-renderers/CellRendererVoucherRulesActiveCheckbox.vue'
import CellRendererVoucherRulesActions from './cell-renderers/CellRendererVoucherRulesActions.vue'

export default {
  components: {
    AgGridVue,
    CellRendererVoucherRulesActiveCheckbox,
    CellRendererVoucherRulesActions
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
      voucherRulesList: [],
      categoryList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererVoucherRulesActiveCheckbox,
        CellRendererVoucherRulesActions
      },
      context: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    // this.voucherRulesList = [
    //   {id: 1, name: 'Voucher 1', isActive: true, created: '2021-12-01'},
    //   {id: 2, name: 'Voucher 2', isActive: false, created: '2021-12-05'},
    //   {id: 3, name: 'Voucher 3', isActive: true, created: '2022-01-03'},
    //   {id: 4, name: 'Voucher 4', isActive: true, created: '2022-01-19'}
    // ]
    
    this.$vs.loading()
      this.$http.get(`/api/voucherrules/apps/${this.applicationId}`)
      .then(response => {
          this.voucherRulesList = response.data
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Actions', width: 150, suppressSizeToFit: true, sortable: false, cellRendererFramework: Vue.extend(CellRendererVoucherRulesActions) },
      { field: 'id', width: 120, suppressSizeToFit: true },
      { headerName: 'Name', field: 'name', width: 300,  suppressSizeToFit: false },
      { headerName: 'Count Of Voucher Codes', field: 'voucherCodeCount', width: 150,  suppressSizeToFit: false },
      { headerName: 'IsActive', field: 'isActive', width: 180,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererVoucherRulesActiveCheckbox) },
      {
        headerName: 'Created',
        field: 'created',
        sortable: true,
        suppressSizeToFit: true,
        width: 250,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    addVoucherRule () {
      this.$router.push({ name: 'application-voucherrules-add', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    deleteVoucherRule (voucherRuleId) {

      this.$vs.loading()
      this.$http.delete(`/api/voucherRules/apps/${this.applicationId}/rules/${voucherRuleId}`)
      .then(response => {
          this.voucherRulesList = this.$_.filter(this.voucherRulesList, x => x.id !== voucherRuleId)
          // Reassign voucherRulesList to trigger change detection
          this.voucherRulesList = JSON.parse(JSON.stringify(this.voucherRulesList))
          this.$_notifySuccess('Voucher rule successfully deleted');
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
    }
  }
}
</script>

<style>

</style>